import { Controller } from '@hotwired/stimulus'
import { analyticsFactory } from 'common/utils/analytics'

/**
 * @typedef {import('common/utils/analytics').AnalyticsSource} AnalyticsSource
 * @typedef {import('common/utils/analytics').TrackingParams} TrackingParams
 */

/**
 * Creates analytics controller with given source
 * @param {AnalyticsSource} source
 * @returns {Controller}
 */
export const analyticsControllerFactory = source => {
  const analyticsInstance = analyticsFactory(source)

  return class extends Controller {
    connect() {
      analyticsInstance.sendPixelData()
    }

    sendPixelData() {
      analyticsInstance.sendPixelData()
    }

    /**
     * @param {{ params: TrackingParams }} e
     */
    track({ params }) {
      analyticsInstance.track(params)
    }
  }
}
